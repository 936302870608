@font-face {
    font-family: "Raleway-bold";
    src: url("./assets/font/Raleway/Raleway-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Raleway-Light";
    src: url("./assets/font/Raleway/Raleway-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Raleway";
    src: url("./assets/font/Raleway/Raleway-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham Bold";
    src: url("./assets/font/Akzidenz/AkzidenzGrotesk-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Akzidenz Grotesk Bold";
    src: url("./assets/font/Akzidenz/AkzidenzGrotesk-Bold.otf") format("opentype");
}

body {
    font-style: normal;
    font-family: "Raleway-medium" !important;
    height: 100%;
    min-width: 450px;
}

table {
    overflow-x: auto;
}

.container-responsive {
    flex-wrap: wrap !important;
    display: flex;
    justify-content: center;
}

p-divider {
    margin: 0px !important;
    color: #22325f7e !important;
}

.p-button {
    padding: 5px 30px !important;
}

.label-check {
    font-size: 14px;
    line-height: 30px;
    color: #22325f;
    font-weight: 500;
    font-family: "Raleway";
}

.max .p-button {
    border-radius: 8px !important;
    background-color: #22325f !important;
    border-color: #22325f !important;
    font-size: 22px !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    padding: 10px 20px !important;
}
.max-secondary .p-button {
    border-radius: 8px !important;
    background-color: #727479 !important;
    border-color: #727479 !important;
    font-size: 22px !important;
    color: black !important;
    font-weight: 700 !important;
    padding: 10px 20px !important;
}
.grant .p-button {
    border-radius: 8px !important;
    background-color: #22325f !important;
    border-color: #22325f !important;
    font-size: 18px !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    width: 100% !important;
}

.basic .p-button {
    border-radius: 8px !important;
    background-color: #22325f !important;
    border-color: #22325f !important;
    font-size: 16px !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    width: 100% !important;
}

.title-white {
    font-size: 28px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 900;
    font-family: "Raleway-bold";
}

.title-pink {
    font-size: 28px;
    line-height: 30px;
    color: #da6d73;
    font-weight: 900;
    font-family: "Raleway-bold";
}

.subtitle-pink {
    font-size: 23px;
    line-height: 30px;
    color: #da6d73;
    font-weight: 900;
    font-family: "Raleway-bold";
}

.subtitle-pink-ligth {
    font-size: 23px;
    line-height: 30px;
    color: #da6d73;
    font-weight: 500;
    font-family: "Raleway-Light";
}

.subtitle-blue {
    font-size: 23px;
    line-height: 30px;
    color: #22325f;
    font-weight: 900;
    font-family: "Raleway-bold";
}

.title-pink-ligth {
    font-size: 28px;
    line-height: 30px;
    color: #da6d73;
    font-weight: 500;
    font-family: "Raleway-Light";
}

.title-white-linght {
    font-size: 28px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Raleway-Light";
}

.title-blue-linght {
    font-size: 28px;
    line-height: 30px;
    color: #22325f;
    font-weight: 500;
    font-family: "Raleway-Light";
}

.title-blue {
    font-size: 28px;
    line-height: 30px;
    color: #22325f !important;
    font-weight: 900;
    font-family: "Raleway-bold";
}
.btn-over .p-button:hover {
    background-color: #576eaf !important;
    border-color: #576eaf !important;
    color: #ffffff !important;
}
.clsfix {
    clear: both;
    float: none;
}
.content {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Raleway-Light";
}
.danger {
    color: red !important;
}
.content-gray {
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 22px;
    color: #525151;
    font-weight: 500;
    font-family: "Raleway-Light";
}

.selected {
    padding: 20px !important;
}

.p-card-content {
    padding: 0px !important;
}

.selected .p-card .p-card-body {
    padding: 0px !important;
}

.selected .p-card .p-card-body .header {
    background-color: rgb(228, 228, 230);
    border-radius: 6px;
}

.selected .p-card .p-card-body .header .pi {
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    padding: 1em;
    text-align: center;
}

.p-inputgroup {
    margin: 0.5em !important;
    width: auto !important;
}

.content-float {
    position: absolute;
    width: 100%;
}

.p-inputgroup-addon {
    font-size: 14px !important;
    color: #22325f !important;
    font-weight: 500 !important;
    font-family: "Raleway" !important;
}

.menu .p-button .p-button-label {
    border-right: 1px solid white !important;
    padding-right: 22px !important;
    margin-right: 0px !important;
}

.label-input {
    margin: 0.5em !important;
    font-size: 1rem;
    line-height: 30px;
    color: #22325f;
    font-weight: 500;
    font-family: "Raleway";
}

.container-w80 {
    width: 80% !important;
    margin: auto;
    justify-content: center;
    padding: 1em;
}

.container-w20 {
    width: 20% !important;
    margin: auto;
    justify-content: center;
    padding: 1em;
}

.atencion {
    background-color: #22325f;
    border-radius: 50px;
    color: white;
    font-size: 12px;
    padding: 4px 9.5px;
}

.p-dropdown {
    width: 100% !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    position: absolute !important;
    right: 15px !important;
    transform: rotate(90deg) !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.75rem !important;
    border: 1px solid #dee2e600 !important;
}

.p-accordion-header-text {
    font-size: 14px !important;
    line-height: 30px !important;
    color: #22325f !important;
    font-weight: 900 !important;
    font-family: "Raleway" !important;
}

.title-banner {
    width: 100%;
    align-items: center;
    display: flex;
}

.title-banner img {
    width: 100%;
    min-height: 3em;
}

.title-banner .title-white {
    position: absolute;
    margin: auto 5%;
}
.cp .p-inputnumber-input {
    max-width: 175px;
}
.radio .p-checkbox-box {
    border-radius: 50% !important;
}
@media screen and (max-width: 1250px) {
    .container-w80 {
        width: 70% !important;
    }
}

@media screen and (max-width: 1050px) {
    .container-w80 {
        width: 80% !important;
    }
}

@media screen and (max-width: 800px) {
    .container-w80 {
        width: 95% !important;
    }

    .title-banner .title-white {
        font-size: 30px;
    }
}

@media (max-width: 767.98px) {
    .p-dialog {
        width: 90vw !important;
    }
    .selected .p-card {
        width: 100% !important;
    }
    .p-inputnumber-input {
        width: 230px;
        max-width: 230px;
    }

    .cp .p-inputnumber-input {
        max-width: 100% !important;
        width: 100% !important;
    }

    .cp .p-inputnumber {
        max-width: 100% !important;
        width: 100% !important;
    }
    p-inputnumber.cp {
        width: 100% !important;
    }
}

@media screen and (max-width: 700px) {
    .title-banner .title-white {
        font-size: 28px;
    }

    .p-inputnumber-input {
        width: 100%;
        max-width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 500px) {
    .title-banner .title-white {
        font-size: 23px;
    }
}
